import React from 'react';
import Highscore from '../components/Highscore';
import Container from '../components/shared/Container';
import PageHeader from '../components/shared/PageHeader';

const HighscorePage = () => (
  <Container>
    <PageHeader title="Highscore" />
    <Highscore />
  </Container>
);

export default HighscorePage;
